<div class="content-position">
    <div class="center-wrapper" *ngIf="!deviceDetect.isDesktop() else desktop" [ngClass]="{'desktop':deviceDetect.isDesktop()}" fxLayoutAlign="center center">
        <div class="page-wrapper" fxLayoutAlign="space-around center" fxLayout="column">
            <div class="page-wrapper" fxLayoutAlign="start center" fxLayout="column">
                <div class="header-wrapper">
                    <div class="header" i18n>LEMON LOTTERY</div>
                </div>
                <div class="wheel-wrapper">
                    <div class="close-btn" (click)="redirectToProfile()">
                        <app-close-dialog-button [customClass]="'medium'" [position]="true"></app-close-dialog-button>
                    </div>
                    <div class="left-spins-info" fxLayout="row" fxLayoutAlign="center center">
                        <ng-container><span i18n>Spins left:</span>&nbsp;<span class="spins-value">{{leftToOpen}}</span></ng-container>
                    </div>
                    <div class="wheel">
                        <img class="wheel-rotate" [@rotatedState]="{value: state, params: {rotationAngle: numberDegressRotation}}" (@rotatedState.done)="animDone($event)" src="assets/gamification/wheel/wheel-rotate-mobile.png" />
                        <div class="indicator" fxLayout="row" fxLayoutAlign="center center">
                            <img src="assets/gamification/wheel/wheel-static-mobile.png" />
                        </div>
                        <ng-container *ngIf="isStartWinAnimation; then desktopwinpresentation"></ng-container>
                        <canvas class="confetti-canvas" #confetticanvas></canvas>
                    </div>
                </div>
                <div class="footer-wrapper" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout="column">
                    <div class="description" i18n>Amazing rewards are waiting for you!</div>
                    <div class="spin-button-wrapper">
                        <button *ngIf="!isFinished" round-button size="large" filled="filled" (click)="spinWheel()" i18n>Spin Now!</button>
                        <button *ngIf="isFinished" round-button size="large" filled="empty" (click)="closeDialog()" i18n>My bonuses</button>
                    </div>
                </div>
                <div class="info-wrapper" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
                    <img src="assets/icons/icon-payment-info.svg" alt="info" />
                    <div class="info-text" i18n>Your winnings are available in My Bonuses.</div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #desktop>
    <div class="center-wrapper-desktop">
        <div class="page-wrapper" fxLayoutAlign="start center" fxLayout="column">
            <div class="close-btn" (click)="redirectToProfile()">
                <app-close-dialog-button [customClass]="'medium'" [position]="true"></app-close-dialog-button>
            </div>
            <div class="header-wrapper">
                <div class="header" i18n>Spin the Wheel to <span>WIN!</span></div>
            </div>
            <div class="wheel-wrapper">
                <div class="left-spins-info" fxLayout="row" fxLayoutAlign="center center">
                    <ng-container><span i18n>Spins left:</span>&nbsp;<span class="spins-value">{{leftToOpen}}</span></ng-container>
                </div>
                <div class="wheel-description" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div class="description" i18n>Amazing rewards are waiting for you!</div>
                    <div class="spin-button-wrapper">
                        <button *ngIf="!isFinished" round-button size="large" filled="filled" (click)="spinWheel()" i18n>Spin Now!</button>
                        <button *ngIf="isFinished" round-button size="large" filled="empty" (click)="closeDialog()" i18n>My bonuses</button>
                    </div>
                    <div class="info-wrapper" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
                        <img src="assets/icons/icon-payment-info.svg" alt="info" />
                        <div class="info-text" i18n>Your winnings are available in My Bonuses.</div>
                    </div>
                </div>
                <div class="wheel">
                    <img class="wheel-rotate" [@rotatedState]="{value: state, params: {rotationAngle: numberDegressRotation}}" (@rotatedState.done)="animDone($event)" src="assets/gamification/wheel/wheel-rotate-desktop.png" />
                    <div class="indicator" fxLayout="row" fxLayoutAlign="center center">
                        <img src="assets/gamification/wheel/wheel-static-desktop.png" />
                    </div>
                    <ng-container *ngIf="isStartWinAnimation; then desktopwinpresentation"></ng-container>
                    <canvas class="confetti-canvas" #confetticanvas></canvas>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #desktopwinpresentation>
    <div class="win-box-overlay" (click)="overlayClicked()">
        <div class="win-box">
            <div class="win-box-wrapper" @fadeIn fxLayoutAlign="center center" fxLayout="column">
                <div class="win-info" i18n>You won!</div>
                <div class="win-value">
                    <div class="value">{{prizeValue}}</div>
                    <div class="name">{{prizeName}}</div>
                </div>
                <!-- <div>
                    <img class="winning-gift" src="assets/gamification/wheel/lottery-winning-gift.png" />
                </div> -->
            </div>
        </div>
    </div>
</ng-template>