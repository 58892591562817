<ng-container *ngIf="prizes?.length >0">
    <h1 class="title" i18n>My Rewards:</h1>
    <div class="rewards-container-list" [ngClass]="isDesktop ? 'desktop' : 'mobile'">
        <div class="rewards-container" *ngFor="let prize of prizes">
            <div class="rewards-box">
                <div class="item">
                    <div class="image-box">
                        <img src="{{licenceService.getKey('icon-bonus-drop')}}" alt="icon-bonus-drop">
                    </div>
                    <div class="name">{{prize.displayName | removehtml}}</div>
                </div>

                <div class="item-tail">
                    <div class="type">{{ prize.prizeType?.toLowerCase() === RewardKeys.BONUS ? RewardTypes.bonus : RewardTypes.freeSpins }}</div>
                    <div class="btn-container" *ngIf="isDesktop">
                        <button round-button size="small" filled="filled" (click)="applyReward(prize.id)" i18n>Activate</button>
                    </div>
                </div>
            </div>
            <div class="btn-container" *ngIf="!isDesktop">
                <button round-button size="small" filled="filled" (click)="applyReward(prize.id)" i18n>Activate</button>
            </div>
        </div>
    </div>
</ng-container>