import { Component, EventEmitter, Output } from '@angular/core';
import { BasePageComponent, BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { PendingRewardsService } from 'src/app/services/prizes/pending-rewards.service';
import { take } from 'rxjs';
import { PendingReward } from 'src/app/services/prizes/prizes.models';
import { MatDialog } from '@angular/material/dialog';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LicenceService } from 'src/app/services/utils/licence.service';

enum RewardKeys {
  BONUS = "bonus",
  FREESPINS = "freespins"
}

@Component({
  selector: 'app-pending-rewards',
  templateUrl: './pending-rewards.component.html',
  styleUrls: ['./pending-rewards.component.scss']
})
export class PendingRewardsComponent extends BasePageComponentWithDialogs {

  RewardTypes = {
    freeSpins: $localize`:@@pendingRewards.freeSpins:freespins`,
    bonus: $localize`:@@pendingRewards.bonus:bonus`
  }

  constructor(
    private pendinRewardsService: PendingRewardsService,
    public errorDialog: MatDialog,
    protected detectDesktopService: DetectDeviceService,
    protected licenceService: LicenceService,
  ) { super(errorDialog, detectDesktopService) }

  prizes: PendingReward[]
  isSubmited = false;
  labelText: string = "";
  RewardKeys = RewardKeys;

  @Output() onAccept: EventEmitter<any> = new EventEmitter();


  ngOnInit(): void {
    this.isDesktop = this.detectDesktopService.isDesktop();
    this.getRewards();
  }

  getRewards() {
    return this.pendinRewardsService.getPendingRewards().pipe(take(1)).subscribe((resp) => {
      this.prizes = resp;
      this.isSubmited = false;
    });
  }

  applyReward(prizeId) {
    if (this.isSubmited) return;
    this.isSubmited = true;
    this.pendinRewardsService.applyReward(prizeId).subscribe({
      next: () => {
        this.onAccept.emit();
        this.getRewards();
        this.openSuccessDialog();
      },
      error: () => {
        this.openErrorDialog();
        this.isSubmited = false;
      }
    })
  }
}
