import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, of } from 'rxjs';
import { FREE_SPINS_LIST, FREE_SPINS_QUEUE, USER_PRIZES_PENDING_REWARDS } from '../http/base-http.service';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HOST_NAME } from '../../global.tokens';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { PendingReward } from './prizes.models';
import { Apollo, gql } from 'apollo-angular';

const rewardsListQuery = gql`query BonusList($locale: Locale!, $externalId: [String], $externalFreeSpinId: [String]) {
  bonuses_val: bonuses(where: {externalId_in: $externalId}, locales: [$locale]) {
    id
    name
    displayName
    externalId
    externalFreeSpinId
  },
  
  freespins_val: bonuses(where: {externalFreeSpinId_in: $externalFreeSpinId}, locales: [$locale]) {
    id
    name
    displayName
    externalId
    externalFreeSpinId
  }
	
}`


@Injectable({
  providedIn: 'root'
})
export class PendingRewardsService extends BaseFirebaseService {


  constructor(
    afAuth: AngularFireAuth,
    http: HttpClient,
    afs: AngularFirestore,
    private loginStatusService: LoginStatusService,
    private apollo: Apollo,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    super(http, afAuth, afs, hostName);
  }

  public getPendingRewards() {
    return this.loginStatusService.getIfUserLogged().pipe(
      concatMap(user => {
        return this.afs.collection('backend-data-users').doc(user.username).collection<PendingReward>('pending-rewards', ref => {
          let query: CollectionReference | Query = ref;
          query = query.orderBy('createdAt', "desc")
          return query
        }).snapshotChanges()
      }),
      map(resp => {
        const respArray = []
        resp.forEach((doc) => {
          const docData = doc.payload.doc.data();
          respArray.push({ id: doc.payload.doc.id, ...docData })
        });
        //console.log('respArray', respArray, this.locale);
        return respArray;
      }),
      switchMap((userBonuses) => {
        if (userBonuses.length > 0) {
          return combineLatest([
            of(userBonuses),
            this.apollo.watchQuery<any>({
              query: rewardsListQuery,
              variables: {
                externalId: userBonuses
                  .filter(b => b.prizeType === "bonus" && b.systemLabel)
                  .map(b => b.systemLabel) || [],
                externalFreeSpinId: userBonuses
                  .filter(b => b.prizeType === "freespins" && b.systemLabel)
                  .map(b => b.systemLabel) || [],
          
                locale: this.locale,
              }
            }).valueChanges.pipe()
          ]).pipe(
            map(([bonuses, cmsResponse]) => {
              //console.log(`* cmsResponse`, cmsResponse)
              const cmsBonuses = cmsResponse?.data?.bonuses_val || [];
              const cmsFreespins = cmsResponse?.data?.freespins_val || [];
  
              const bonusesMap: Map<string, any> = new Map([
                ...cmsBonuses.map(b => [b.externalId, b]),
                ...cmsFreespins.map(b => [b.externalFreeSpinId, b])
              ]);
  
              return bonuses.map((b) => {
                const matchingBonus = bonusesMap.get(b.systemLabel);
                return matchingBonus ? { ...b, displayName: matchingBonus.displayName } : b;
              });
            })
          )
        } else {
          return of([])
        }
      }),
      //tap((res) => console.log(`* PendingRewardsService getPendingRewards() => tap() => res`, res)),
    )
  }

  public applyReward(prizeId) {
    return this.post(USER_PRIZES_PENDING_REWARDS, { prizeId: prizeId })
  }
}
